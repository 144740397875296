@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Barlow', sans-serif;
}

a {
  font-family: 'Barlow', sans-serif;
  color: #666cff;
  text-decoration: none;
  cursor: pointer;
}

span {
  line-height: 1.25;
}

.shadow {
  box-shadow: 0px 1.5px 4px 0px rgba(13, 10, 44, 0.1);
}
//------------------------------ texts ------------------------------\\
.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

//------------------------------ svg filters ------------------------------\\
.svgcolor-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(208deg) brightness(105%) contrast(101%);
}
.svgcolor-666cff {
  filter: invert(39%) sepia(41%) saturate(1934%) hue-rotate(215deg) brightness(102%) contrast(101%);
}
.svgcolor-2b2c2c {
  filter: invert(15%) sepia(6%) saturate(146%) hue-rotate(131deg) brightness(97%) contrast(92%);
}

//------------------------------ snackbar ------------------------------\\
.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: #666cff;
    --mat-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.error-snackbar {
    --mdc-snackbar-container-color: #e60000;
    --mat-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-card {
  border-radius: 24px !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  --mdc-dialog-container-shape: 24px;
}

// .mat-mdc-menu-item:hover {
//   background-color: #666cff !important;
//   color: white;
// }

.mdc-switch,
.mdc-switch__track {
  border-radius: 100px !important;
  height: 32px !important;
  width: 56px !important;
}

.mdc-switch__handle {
  height: 24px !important;
  width: 24px !important;
  border-radius: 100px !important;
}

.mdc-switch__icons {
  visibility: hidden !important;
}

.mdc-switch__shadow {
  background-color: #fff;
}

.mdc-switch .mdc-switch__handle-track {
  width: 22px !important;
  margin-left: 4px !important;
}

.mdc-switch:enabled .mdc-switch__track::before {
  background-color: #afafaf !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background-color: #666cff !important;
}

.mdc-button {
  min-height: 54px !important;
  min-width: 90px !important;
  border-radius: 12px !important;
  border-width: 1px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.mat-mdc-outlined-button:valid {
  border-color: #666cff !important;
  color: #666cff !important;
  font-size: 18px !important;
  --mat-outlined-button-state-layer-color: #fff !important;
}

.mat-mdc-unelevated-button {
  color: #fff !important;
  font-size: 18px !important;
}

.mdc-text-field--disabled {
  input,
  label {
    color: #7a7a7a !important;
  }
  background-color: #ebebeb !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-menu-content {
  padding: 0.5rem !important;
  .mat-mdc-menu-item:hover,
  a:hover {
    border-radius: 12px;
    background-color: #c2c4ff !important;
    color: #141633 !important;
  }
}

.mat-mdc-menu-panel {
  max-width: 380px !important;
  border-radius: 20px !important;
}

///// STYLES FOR CUSTOM PAGINATOR /////
.custom-paginator-container {
  justify-content: flex-end;
}

.custom-paginator-counter {
  white-space: nowrap;
  margin: 0px 4px 0px 10px !important;
  display: none !important;
}

.custom-paginator-page {
  border-radius: 4px;
  outline: none;
  border: none;
  margin: 0.4em;
  font-size: 14px;
  min-width: 24px;
  width: auto;
  min-height: 24px;
  max-height: 24px;
  padding: 1px 6px;
}

.custom-paginator-page-enabled {
  @apply text-disabled;
  background: transparent;
  cursor: pointer;
  &:hover {
    @apply bg-background;
  }
}

.custom-paginator-page-disabled {
  @apply text-disabled;
  @apply bg-primary text-white;
}

.custom-paginator-arrow-disabled {
  @apply text-disabled;
  display: none !important;
}

.custom-paginator-arrow-enabled {
  @apply text-disabled;
}

// scroll

/* Tamaño del scroll */
::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra de scroll */
::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 4px;
}

/* Estilos track de scroll */
::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 4px;
}

::selection {
  background-color: #666cff;
  color: white;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}

mat-form-field.small {
  --mat-form-field-container-height: 46px;
  --mat-form-field-container-vertical-padding: 10px;
}

.customIconSize {
  height: 68px !important;
  width: 68px !important;
}
